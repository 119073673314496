/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '../node_modules/@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '../node_modules/@ionic/angular/css/normalize.css';
@import '../node_modules/@ionic/angular/css/structure.css';
@import '../node_modules/@ionic/angular/css/typography.css';
@import '../node_modules/@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '../node_modules/@ionic/angular/css/padding.css';
@import '../node_modules/@ionic/angular/css/float-elements.css';
@import '../node_modules/@ionic/angular/css/text-alignment.css';
@import '../node_modules/@ionic/angular/css/text-transformation.css';
@import '../node_modules/@ionic/angular/css/flex-utils.css';

@import '../node_modules/tippy.js/dist/tippy.css';

@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';

@import '../node_modules/highlight.js/styles/default.css';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

a {
  cursor: pointer;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

h1 {
  font-size: 1.3rem;
}

h2 {
  font-size: 1.15rem;
}

h3 {
  font-size: 1rem;
}

// body {
//     -webkit-user-select: text;
//     -moz-user-select: text;
//     -ms-user-select: text;
//     user-select: text;
// }

ion-content {
  --background: var(--ion-background-color);
}

ion-header,
ion-footer {
  background: var(--ion-toolbar-background);
}

ion-toolbar {
  --min-height: 44px;
}

ion-title {
  font-weight: 600;
  font-size: 0.85rem;
}

ion-button {
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 0.8rem;
  --padding-start: 0.8rem;
  --padding-end: 0.8rem;
  transition: background-color 0s !important;
}

ion-button:active {
  filter: brightness(0.8);
  /* Adjust the brightness value as needed */
}

ion-button:not(.button-round) {
  --border-radius: 8px;
}

ion-button.button-outline {
  --border-width: 1px;
}

ion-button[fill='clear']:active {
  --background-activated-opacity: 0.1;
}

[data-theme='dark'] {
  ion-button:not([fill='clear']):not([fill='outline']):not(.in-buttons) {
    --background: #005eea !important;
  }
}

ion-list ion-checkbox {
  margin: 0 12px 0 0;
}

ion-alert,
ion-modal {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.sc-ion-alert-md-h {
  --max-width: 340px;
}

.alert-button.sc-ion-alert-md {
  text-transform: none;
  border-radius: 8px;
}

.alert-button-inner.sc-ion-alert-md {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.alert-button.sc-ion-alert-md:hover {
  background: var(--ion-color-step-100);
}

.alert-title.sc-ion-alert-md {
  font-size: 1.1rem;
}

.alert-head.sc-ion-alert-md {
  -webkit-padding-start: 18px;
  padding-inline-start: 18px;
  -webkit-padding-end: 18px;
  padding-inline-end: 18px;
}

.ion-overlay-wrapper {
  border-radius: 10px !important;
}

ion-modal {
  &::part(content) {
    border-radius: 14px;
  }
}

ion-modal.datetime-modal {
  &::part(content) {
    height: fit-content;
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 16px;
    }
  }
}

ion-popover {
  &::part(content) {
    border-radius: 10px;
  }
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      font-size: 0.9rem;
      margin: 0;
    }
  }
}

.button-round {
  --padding-start: 15px;
  --padding-end: 15px;
}

.button-icon {
  font-size: 0.8rem;
}

.button-dark-border {
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  --border-radius: 8px;
  --padding-start: 0.6rem;
  --padding-end: 0.6rem;
}

.btn-large {
  font-size: 1rem;
  height: 48px;
}

.btn-small {
  font-size: 0.75rem;
  height: 30px;
}

.btn-xs {
  font-size: 0.68rem;
  height: 26px;

  .button-icon {
    font-size: 0.62rem;
  }
}

.fake-button {
  font-weight: 400;
}

ion-card,
.ion-card {
  color: inherit;
  box-shadow: 0px 1px 4px 0px var(--card-shadow-color);
  border-radius: 8px;
  background: var(--ion-item-background);
}

ion-card-subtitle {
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
}

.outline-card {
  box-shadow: 0px 0px 0px 1px var(--card-shadow-color);
}

.bordered-card {
  border: 1px solid var(--card-border-color);
}

.card-selected {
  box-shadow: 0px 0px 0px 2px var(--ion-color-primary);

  ion-icon {
    color: var(--ion-color-primary);
  }

  ion-label {
    color: var(--ion-text-color);
  }
}

.content-top-shadow {
  width: 100%;
  top: 0;
  box-shadow: 0px 0px 2px 1px rgb(127 131 134 / 12%);
  position: fixed;
  z-index: 99999;
}

ion-searchbar {
  .searchbar-input-container {
    .searchbar-input {
      font-size: 0.85rem !important;
      background: var(--ion-item-background);
      border-radius: 5px !important;
    }

    .searchbar-search-icon {
      left: 7px;
      width: 18px;
    }
  }
}

ion-icon {
  vertical-align: middle;
}

ion-item {
  --border-color: var(--ion-color-step-50);
  font-size: 0.8rem;
  --background-focused-opacity: 0.06;
  --background-hover-opacity: 0.04;
  background: transparent;
  --background: transparent;
  --min-height: 40px;
  --ripple-color: transparent;
  --background-activated: transparent;

  .has-focus {
    box-shadow: none;
  }
}

ion-item:last-child {
  --border-width: 0;
  --border-color: transparent;
}

.item-accessory-button {
  margin-left: 5px;
  --padding-start: 0;
  --padding-end: 0;
  width: 26px;
  height: 26px;
  font-size: 0.75rem;
}

.ion-item-rounded {
  font-weight: 400;
  --min-height: 36px;
  margin: 0 5px;
  border-radius: 8px;
  --padding-start: 8px;
}

.sc-ion-label-md-s p {
  font-size: 0.8rem;
}

.ion-item-rounded-no-margin {
  margin: 0 !important;
}

ion-badge {
  border-radius: 6px;
  padding: 5px 6px;
  font-weight: 500;
  font-size: 0.7rem;
}

ion-badge[color='success'] {
  background: rgba(var(--ion-color-success-rgb), 0.15);
  box-shadow: inset 0 0 0px 1px rgba(var(--ion-color-success-rgb), 0.8);
  color: var(--ion-color-success);
}

ion-badge[color='warning'] {
  background: rgba(var(--ion-color-warning-rgb), 0.15);
  box-shadow: inset 0 0 0px 1px rgba(var(--ion-color-warning-rgb), 0.8);
  color: var(--ion-color-warning);
}

ion-badge[color='dark'] {
  background: rgba(var(--ion-color-dark-rgb), 0.9);
  box-shadow: inset 0 0 0px 1px rgba(var(--ion-color-dark-rgb), 0.9);
  color: var(--ion-color-light);
}

ion-badge[color='light'] {
  background: transparent;
  box-shadow: inset 0 0 0px 1px rgba(var(--ion-color-dark-rgb), 0.15);
  color: var(--ion-color-dark);
}

ion-badge[color='primary'] {
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  box-shadow: inset 0 0 0px 1px rgba(var(--ion-color-primary-rgb), 0.8);
  color: var(--ion-color-primary);
}

ion-card-title {
  font-weight: 600;
  font-size: 1.05rem;
}

ion-fab-button {
  --box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

ion-chip {
  font-size: 0.8rem;
}

ion-chip:active {
  filter: brightness(0.8);
  /* Adjust the brightness value as needed */
}

ion-select-popover {
  ion-list {
    padding: 5px 2px !important;

    ion-item {
      font-weight: 400;
      --min-height: 28px;
      margin: 0 3px;
      border-radius: 8px;
      --padding-start: 12px;
    }
  }
}

.custom-select::part(icon) {
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-select.select-expanded::part(icon) {
  transform: rotate(180deg);
}

.popover-viewport {
  overflow: auto !important;
}

.pill-button-active {
  background: var(--ion-color-step-50);
  border-radius: var(--input-border-radius);
  box-shadow: 0px 0px 0px 1px var(--ion-color-step-100);
}

.header-md:after {
  background-position: left 0 top -5px;
}

.constrained-content {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-margin-left {
  margin-left: 0;
}

.grid-no-padding {
  padding: 0;

  ion-row {
    padding: 0;

    ion-col {
      padding: 0;
    }
  }
}

.transparent-bg {
  background: transparent;
  --background: transparent;
}

.button-solid {
  --box-shadow: none;
}

.button-round-left {
  --border-radius: 99px 0 0 99px;
}

.button-round-right {
  --border-radius: 0 99px 99px 0;
}

.button-square {
  --border-radius: 0;
}

.add-caret {
  font-size: 0.7rem;
  padding-left: 5px;
  opacity: 0.7;
}

.mobile-menu-toggle {
  margin: 10px 0 -10px 5px;
}

.depthButton {
  cursor: pointer;
  transition: all 0.15s ease;
}

.depthButton:hover {
  transform: translateY(-1px);
  box-shadow: 0px 2px 6px 0px var(--depth-button-hover);
}

.depthButton:active {
  color: #5778f3;
  transform: translateY(0px);
  box-shadow: 0px 1px 1px 0px #a9b0b63b;
}

.light-shadow {
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 16%);
}

.deep-shadow {
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 16%);
}

.editable-title:hover {
  color: var(--ion-color-primary);
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.15);
}

.padded {
  padding: 10px;
}

.clickable {
  cursor: pointer;
}

.text-superbold {
  font-weight: 700;
}

.text-superbig {
  font-size: 3.2rem;
}

.code {
  font-family: monospace;
  color: var(--ion-text-color);
  background: var(--ion-color-step-50);
  border: 1px solid var(--ion-color-step-100);
  border-radius: 5px;
  padding: 2px;
}

.blockquote {
  border-left: 2px solid var(--ion-color-step-100);
  margin: 12px;
  padding: 0 15px;
  color: var(--ion-color-step-600);
}

.mention-tag {
  border: 1px solid var(--ion-color-primary);
  border-radius: 0.4rem;
  padding: 1px 2px;
  margin: 0 2px;
  box-decoration-break: clone;
}

span[data-type='slack-broadcast'],
span[data-type='slack-channel-mention'],
span[data-type='slack-usergroup-mention'],
span[data-type='user-mention'] {
  border: 1px solid var(--ion-color-primary);
  border-radius: 0.4rem;
  padding: 1px 2px;
  margin: 0 2px;
  box-decoration-break: clone;
}

.ProseMirror:focus-visible {
  outline: none;
}

.ProseMirror {
  padding: 0 15px;
  font-size: 0.9rem;

  p {
    margin: 10px 0;
  }
}

p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.top-searchbar {
  ion-searchbar {
    height: 54px;
  }

  ion-button {
    margin: 9px;
  }
}

.search-bar {
  margin: 4px 6px;
  padding: 0;
  width: 250px;
  height: 32px;
  border-radius: var(--input-border-radius);
}

.rounded-search-bar {
  margin: 5px;
  padding: 0;
  width: 270px;
  height: 35px;
  box-shadow: 0px 0px 0px 1px #c7cfd633;
  border-radius: var(--input-border-radius);
}

.plan-card {
  margin: 7px 14px;
  cursor: pointer;

  ion-card-title {
    margin-bottom: 10px;
    margin-top: 2px;
    font-weight: 700;
  }

  ion-card-subtitle {
    font-size: 0.9rem;
  }
}

.icon-padded,
.padded-icon {
  padding-right: 7px;
}

.icon-padded-left,
.padded-icon-left {
  padding-left: 7px;
}

.text-with-left-icon {
  margin-left: 7px;
}

.text-with-right-icon {
  margin-right: 7px;
}

.input-label {
  font-size: 0.75rem;
  opacity: 0.8;
}

.input-field {
  margin-top: 6px;
  margin-bottom: 12px;
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  color: var(--ion-text-color);
  background: var(--ion-item-background);
  --padding-start: 8px;
  --padding-end: 8px;
  font-size: 0.85rem;

  .textarea-wrapper {
    textarea:focus {
      border-radius: var(--input-border-radius);
      box-shadow: 0 0 0px 3px rgba(var(--ion-color-primary-rgb), 0.4);
    }
  }
}

.select-outline {
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  font-size: 0.75rem;
  padding: 6px 8px 6px 11px;
  font-weight: 500;
}

.has-focus,
.search-bar.searchbar-has-focus {
  border-color: var(--ion-color-primary);
  box-shadow: 0 0 0px 3px rgba(var(--ion-color-primary-rgb), 0.4);
}

.select-outline.select-expanded {
  box-shadow: 0 0 0px 3px rgba(var(--ion-color-primary-rgb), 0.4);
}

.fullscreen-spinner-container {
  padding-top: 38vh;
  text-align: center;
  height: 100%;
  width: 100%;
}

.fullscreen-spinner {
  color: #999999;
  font-weight: 500;
}

.info-tooltip {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.big-title-input {
  font-size: 1.3rem;
  font-weight: 500;

  input {
    --padding-left: 0px;
  }
}

.text-link {
  text-decoration: none;
}

.top-preview-bar {
  background: #e2fcde;
  color: #1c6411;
  text-align: center;
  padding: 15px;
  font-size: 0.8rem;
}

.tag-chip {
  cursor: default;
  padding: 5px;
  border-radius: 6px;
  height: 22px;
  border: 1px solid var(--ion-color-primary);
}

.tag-chip-light {
  background: var(--ion-color-step-50);
  border-color: var(--ion-color-step-100);
}

.status-label {
  border-radius: 6px;
  font-size: 0.7rem;
  padding: 4px 8px 4px 4px;
  white-space: nowrap;

  ion-icon {
    font-size: 0.9rem;
    margin-right: 1px;
    margin-top: -1px;
    margin-left: 1px;
  }
}

.status-label-needs-response {
  color: var(--ion-color-danger);
}

.status-label-in-progress {
  color: var(--ion-color-warning);
}

.status-label-on-hold {
  color: var(--ion-color-dark);
}

.status-label-closed {
  color: var(--ion-color-success);
}

/*
 * Padding utils
 */
.padded-10 {
  padding: 10px;
}

.padded-15 {
  padding: 15px;
}

.padded-20 {
  padding: 20px;
}

/*
 * Flexbox
 */
.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.inline-flexed {
  display: inline-flex;
}

.inline-flexed-align-center {
  display: inline-flex;
  align-items: center;
}

.flexed {
  display: flex;
}

.flexed-align-center {
  display: flex;
  align-items: center;
}

/*
 * Modals
 */
.modal-title {
  margin-left: 16px;
  display: flex;
  color: var(--ion-text-color);
  align-items: center;
  width: 100%;

  h3 {
    margin: 14px 0;
  }

  .subtitle {
    margin: 6px 15px;
    opacity: 0.5;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-sm {
  &::part(content) {
    --width: 500px;
  }
}

.modal-medium {
  &::part(content) {
    --width: 800px;
    --height: 85vh;
  }
}

.modal-large {
  &::part(content) {
    --min-width: 70vw;
    --min-height: 85vh;
  }
}

.modal-fit-width {
  &::part(content) {
    width: auto;
    max-width: 100vw;
    height: 760px;
    max-height: 100vh;
  }
}

.modal-content-only {
  &::part(content) {
    height: fit-content;
  }
}

.modal-full-screen {
  --width: 100%;
  --height: 100%;
  &::part(content) {
    border-radius: 0;
  }
}

.modal-right {
  --height: 100vh;
  justify-content: right;
}

.modal-tall {
  --height: 90vh;
}

/*
 * Table
 */
.ngx-datatable.material {
  box-shadow: none;
  height: 100%;
  background: var(--table-background) !important;

  .empty-row {
    opacity: 0.2;
    padding: 1rem 1.2rem 1.2rem;
  }
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: unset;
}

.engagement-status-column {
  padding: 10px !important;
}

.datatable-body-row,
.datatable-footer,
.datatable-body,
.datatable-body-cell,
.datatable-body-cell-label {
  background: var(--table-background) !important;
  color: var(--ion-text-color) !important;
  display: flex !important;
  align-items: center !important;
}

.datatable-body-cell-label {
  height: 100% !important;
}

.datatable-header,
.datatable-header-cell {
  background: var(--table-background) !important;
  color: var(--ion-color-step-600) !important;
  font-size: 0.8rem !important;
}

.datatable-header {
  border-bottom: 1px solid var(--ion-subtle-border-color) !important;
}

.datatable-footer {
  border-top: 1px solid var(--ion-subtle-border-color) !important;
}

.datatable-checkbox input[type='checkbox']:after {
  background: var(--ion-color-step-0);
}

.datatable-checkbox input[type='checkbox']:before {
  border-radius: 2px;
}

.page-count {
  color: var(--ion-color-step-600);
}

.datatable-checkbox input[type='checkbox']:before {
  border: 1px solid var(--ion-color-step-100);
}

.ngx-datatable .datatable-footer .datatable-pager .pager a {
  color: var(--ion-color-step-600);
}

.datatable-row-borders {
  .datatable-row-wrapper {
    border-bottom: 1px solid var(--ion-subtle-border-color) !important;
  }
}

.datatable-row-top-aligned {
  .datatable-body-cell-label {
    display: inline !important;
  }
}

.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  margin: 0 60px;
}

/*
 * Settings
 */
.settings-title-section {
  h1 {
    font-size: 1.05rem;
    font-weight: 500;
    margin-top: 28px;

    ion-icon {
      opacity: 0.75;
      font-size: 1.7rem;
      margin-right: 10px;
    }
  }

  .subtitle {
    margin: 0;
    font-size: 0.85rem;
    padding: 0px 10px 0 37px;
  }
}

.settings-learn-more {
  font-size: 0.85rem;
  padding: 0px 30px;
}

/*
 * Reports & Analytics
 */
.report-card {
  padding: 15px;
}

.report-title {
  font-weight: 500;
  margin-top: 8px;
  margin-left: 10px;
  margin-bottom: 5px;
  color: var(--ion-color-step-600);
}

.report-stat {
  margin-left: 10px;
  margin-bottom: 3px;
  font-weight: 600;
  color: var(--ion-text-color);
  font-size: 2.4rem;
}

.notifications-popover {
  &::part(content) {
    width: 354px;
  }
}

.emoji-picker-popover {
  &::part(content) {
    width: 352px;
  }
}

/*
 * Left nav
 */

.left-nav-menu {
  border-inline-end: 1px solid var(--ion-subtle-border-color);
}

.skinny-left-nav-split-pane {
  --side-min-width: 230px;
  --side-max-width: 230px;
}

.skinny-left-nav-menu {
  width: 230px;
}

@media (max-width: 991px) {
  .left-nav-menu,
  .skinny-left-nav-menu {
    width: 100%;
  }
}

.left-nav-back-button {
  font-size: 1.3rem;
  margin-left: 15px;
}

.left-nav-title {
  font-weight: 600;
  margin: 25px 15px 10px 15px;
  font-size: 1.2rem;
}

ion-menu.md ion-content {
  --background: var(--ion-item-background);
}

ion-menu.md ion-item {
  --padding-start: 8px;
  --padding-end: 10px;
  border-radius: 999px;
}

ion-menu.md ion-item.selected {
  --background: rgb(var(--left-nav-highlighted-item-rgb));
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-size: 0.9rem;
}

.row-title {
  font-weight: 500;
}

.row-link {
  transition: all 0.15s ease;
  color: var(--ion-color-step-900);
}

.row-link:hover {
  color: var(--ion-color-primary);
}

/*
 * Nav bar for admins
 */
.admin-bar {
  padding: 10px;
  background: rgba(var(--ion-color-success-rgb), 0.03);
  color: var(--ion-color-success);
  font-size: 0.8rem;
}

.admin-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*
 * Pill tabs
 */
.round-pill-tab {
  padding: 5px 7px;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  transition: all 0.15s ease;
  color: var(--ion-color-step-500);
  border-radius: 5px;
  margin: 0 2px;
}

.round-pill-tab:hover,
.round-pill-tab-active {
  color: var(--ion-text-color);
  background: var(--highlighted-pill-background);
  box-shadow: 0px 0px 1px 1px var(--ion-color-step-100);
}

.pill-tab-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 3px 1px;
  border: 1px solid var(--input-border-color);
  background: var(--ion-background-color);
  border-radius: 8px;
}

/*
 * Tab nav (instead of segments)
 */
.tab-nav-link {
  cursor: pointer;
  padding: 14px 0;
  margin: 0 5px;
  font-size: 0.8rem;
  font-weight: 500;
  transition: all 0.15s ease;
  color: var(--ion-color-step-600);
}

.tab-nav-link:first-child {
  margin-left: 15px;
}

.tab-nav-link:hover,
.tab-nav-active {
  color: var(--ion-text-color);
  box-shadow: inset 0px -1.5px 0px 0px var(--ion-color-primary);
}

.tab-nav-badge {
  vertical-align: middle;
  padding: 4px 8px;
  margin-left: 5px;
}

/*
 * Left tab / inbox filters
 */
.inbox-list {
  padding: 10px 0;

  ion-item {
    cursor: pointer;
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
    --min-height: 44px;
    --background-hover: rgba(var(--ion-color-primary-rgb), 0.08);
  }

  .selected ion-icon {
    color: var(--ion-text-color);
  }

  ion-item ion-icon {
    color: #616e7e;
    margin: 0 12px 0 8px;
    font-size: 1.2rem;
  }

  ion-item ion-label {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .selected {
    --color: var(--ion-text-color);
    --background: rgba(var(--ion-color-primary-rgb), 0.11);
  }
}

/*
 * Popovers
 */
ion-popover::part(content) {
  width: 312px;
  --box-shadow: var(--ion-box-shadow);
}

ion-popover.wide-popover::part(content) {
  width: 355px;
}

ion-popover.extra-wide-popover::part(content) {
  width: 400px;
}

/*
 * Empty state
 */
.empty-state-container {
  height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .placeholder-emoji {
    opacity: 0.2;
    font-size: 7rem;
  }

  .placeholder-title {
    opacity: 0.6;
  }

  .placeholder-subtitle {
    opacity: 0.3;
  }
}

/*
 * Tippy
 */
.tippy-box {
  padding: 3px 5px;
  border-radius: 6px;
  box-shadow: var(--ion-box-shadow);
}

.tippy-box.editor-tippy {
  color: var(--ion-text-color);
  background-color: var(--left-nav-background);
  padding: 0;
}

/*
 * Responsive utilities
 */

.shown-sm {
  display: none;
}

.shown-md {
  display: none;
}

.shown-lg {
  display: none;
}

@media (max-width: 1240px) {
  .hidden-xl {
    display: none;
  }
}

@media (max-width: 992px) {
  .hidden-lg {
    display: none;
  }

  .shown-lg {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .left-nav-menu {
    max-width: 270px;
  }
}

@media (max-width: 720px) {
  .hidden-md {
    display: none;
  }

  .shown-md {
    display: inline-block;
  }
}

@media (max-width: 540px) {
  .hidden-sm {
    display: none;
  }

  .shown-sm {
    display: inline-block;
  }
}

.ng-select.ng-select-multiple.autocomplete-override .ng-select-container .ng-value-container .ng-placeholder {
  top: initial;
  padding-bottom: 0;
}

.ng-select.ng-select-multiple.autocomplete-override .ng-select-container {
  border: none;
}

.ng-select.ng-select-multiple.autocomplete-override .ng-select-container .ng-value-container .ng-value {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 16px;
  border-color: #bbb;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  padding: 7px 9px;
  margin: 4px;
  font-family: var(--ion-font-family, inherit);
  font-size: 14px;
  line-height: 1;
}

.ng-select.ng-select-multiple.autocomplete-override
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: none;
  border-radius: 32px;
  color: white;
  background-color: black;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ng-select-container {
  background: var(--ion-item-background) !important;
  color: var(--ion-text-color) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 5px 0;
}

.ng-select .ng-select-container:hover {
  box-shadow: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  color: var(--ion-text-color);
}

.ng-value-label {
  color: var(--ion-text-color);
}

.cursor-based-paging .pager {
  margin-right: 40px !important;

  li:first-child {
    display: none !important;
  }

  li:last-child {
    display: none !important;
  }

  li.pages {
    display: none !important;
  }
}

.ion-chip-accessory-button {
  background: #c9d7ee;
  color: var(--ion-color-primary);
  padding: 4px;
  margin-left: 8px;
  margin-right: -6px;
  border-radius: 999px;
}

.wide-alert-controller {
  --max-width: 600px;
  --min-width: 600px;
}

.service-account-token-modal {
  .alert-wrapper {
    max-width: 600px;
    user-select: text;
  }
}

.admin-settings-warning-modal {
  .alert-wrapper {
    max-width: 600px;
    user-select: text;
  }
}

.upgrade-feature-banner {
  background: #7257cc;
  background-image: linear-gradient(135deg, #924cff, #6583ff);
  color: #ffffff;
  padding: 10px 20px;
  align-items: center;
  font-size: 0.85rem;
}

.warning-feature-banner {
  background: var(--ion-color-warning);
  background-image: linear-gradient(135deg, var(--ion-color-danger), var(--ion-color-warning));
  color: #ffffff;
  padding: 10px 20px;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  justify-content: center;

  ion-button {
    margin: 0 10px;
  }
}

/* The switch - the box around the slider */
.switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--ion-color-step-0);
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #adb5bd;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #007bff;
  border: 1px solid #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}

.filter-button-icon {
  font-size: 0.85rem;
  margin-left: 2px;
  margin-right: 6px;
  color: var(--ion-color-primary);
}

.filter-button-caret {
  font-size: 0.9rem;
  margin-left: 6px;
}

.icon-colored {
  padding: 4px;
  border-radius: 8px;
  margin-right: 4px;
  color: white;
}

.bg-primary {
  background: var(--ion-color-primary);
}
.bg-secondary {
  background: var(--ion-color-secondary);
}
.bg-tertiary {
  background: var(--ion-color-tertiary);
}

.validate-email-domain-modal {
  &::part(content) {
    height: 500px;
    width: 65%;
  }
}

.generate-service-account-modal {
  &::part(content) {
    height: 400px;
  }
}

.automation-run-logs-modal {
  &::part(content) {
    height: 600;
    width: 75%;
  }
}

.ngx-datatable.material .datatable-body {
  min-height: 5px;
}

.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}

.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}

.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}

datatable-body-row.totals-row {
  border-top: 2px solid var(--ion-subtle-border-color);
}

.rounded-shadow-section {
  margin: 8px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 2px 2px var(--card-shadow-color);
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

.linear-gradient-subtle-animated {
  background: linear-gradient(135deg, #9100ca33, #6ebbff33, #9100ca33, #6ebbff33, #9100ca33);
  background-size: 200% 200%;

  -webkit-animation: LinearGradientAnimation 2s linear infinite;
  -moz-animation: LinearGradientAnimation 2s linear infinite;
  animation: LinearGradientAnimation 2s linear infinite;
}

@-webkit-keyframes LinearGradientAnimation {
  0% {
    background-position: 0% 0%;
    box-shadow: inset 0 0 10px #9100ca33;
  }

  50% {
    box-shadow: inset 0 0 30px #9100ca66;
  }

  100% {
    background-position: 100% 100%;
    box-shadow: inset 0 0 10px #9100ca33;
  }
}

@-moz-keyframes LinearGradientAnimation {
  0% {
    background-position: 0% 0%;
    box-shadow: inset 0 0 10px #9100ca33;
  }

  50% {
    box-shadow: inset 0 0 30px #9100ca66;
  }

  100% {
    background-position: 100% 100%;
    box-shadow: inset 0 0 10px #9100ca33;
  }
}

@keyframes LinearGradientAnimation {
  0% {
    background-position: 0% 0%;
    box-shadow: inset 0 0 10px #9100ca33;
  }

  50% {
    box-shadow: inset 0 0 30px #9100ca66;
  }

  100% {
    background-position: 100% 100%;
    box-shadow: inset 0 0 10px #9100ca33;
  }
}

.saved-filter-modal,
.saved-report-modal {
  &::part(content) {
    height: 267px;
  }
}

.block-element {
  display: block;
}

.conversation-message-inline-images-container {
  & > img {
    display: block;
    margin-top: 10px;
  }

  & > img:first-child {
    margin-top: 0px;
  }
}

.custom-emoji,
span[data-type='custom-emoji'] img {
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 0 1px -4px 1px;
}

// Specific styling on searchable pop over in messages
.viewFromMessageInboxHeight {
  height: 200px;
  overflow-y: scroll;
}

.editor-container {
  height: 100%;
}

.slack-message {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & p,
  & ul,
  & ol,
  & hr,
  & pre,
  & blockquote {
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }

  & [data-type='survey'] {
    display: contents;
  }

  & .slack-section {
    display: flex;

    & .slack-section-content {
      width: 100%;
      height: fit-content;
    }

    & .slack-section-accessory {
      width: 88px;
      height: fit-content;
      min-width: 88px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & img {
        border-radius: 4px;
        height: 88px;
      }
    }
  }

  & .slack-header {
    font-size: 1rem;
    margin: 0;
  }

  & .slack-context {
    display: flex;
    gap: 15px;
    row-gap: 10px;
    flex-flow: wrap;
  }

  & .slack-actions {
    border: none;
    margin: 0;

    & ul,
    & ol {
      list-style: none;
      display: flex;
      gap: 10px;
      row-gap: 10px;
      flex-flow: wrap;

      & li {
        cursor: not-allowed;
      }
    }
  }

  & .slack-button {
    background-color: transparent;
    pointer-events: none;
    text-decoration: none;
    border-radius: 8px;
    color: var(--ion-color-base);
    font-size: 12px;
    border: 1px solid black;
    display: block;
    padding: 5px 10px;
    line-height: 12px;

    &[data-slack-style='primary'] {
      background-color: var(--ion-color-success);
      color: white;
      border-color: var(--ion-color-success);
    }

    // Hiding this because it's pretty useless to display in conversation details page.
    &.slack-overflow-button {
      display: none;
    }
  }

  & .slack-divider {
    display: block;
    height: 1px;
    border: 0;
    border-bottom: 1px solid rgb(221, 221, 221);
    width: 100%;
  }

  & span[data-type='color']::after {
    display: inline-block;
    background-color: var(--color-hex-value);
    border: 1px solid rgba(210, 210, 210, 0.7);
    padding: 0.43rem;
    content: '';
    margin: 0 0 0 0.2rem;
    vertical-align: text-top;
    border-radius: 3px;
  }

  & .slack-rich-text-list {
    padding-left: 15px;
    list-style-type: inside;
  }

  & .slack-rich-text-list {
    &:is(ul) {
      list-style-type: disc;
    }

    &:is(ol) {
      list-style-type: decimal;
    }

    & .slack-rich-text-list {
      &:is(ul) {
        list-style-type: circle;
      }

      &:is(ol) {
        list-style-type: lower-alpha;
      }

      & .slack-rich-text-list {
        &:is(ul) {
          list-style-type: square;
        }

        &:is(ol) {
          list-style-type: lower-roman;
        }

        & .slack-rich-text-list {
          &:is(ul) {
            list-style-type: disc;
          }

          &:is(ol) {
            list-style-type: decimal;
          }

          & .slack-rich-text-list {
            &:is(ul) {
              list-style-type: circle;
            }

            &:is(ol) {
              list-style-type: lower-alpha;
            }

            & .slack-rich-text-list {
              &:is(ul) {
                list-style-type: square;
              }

              &:is(ol) {
                list-style-type: lower-roman;
              }
            }
          }
        }
      }
    }
  }

  blockquote {
    border-left: 2px solid #d1d1d1;
    padding-left: 10px;
  }

  & pre,
  & code {
    color: var(--ion-text-color);
    background-color: var(--ion-color-step-50);
    border: 1px solid var(--ion-color-step-100);

    border-radius: 4px;
    padding: 2px 4px;
    font-family: monospace;
    font-size: 0.75rem;
  }

  & pre code {
    border: unset;
    background-color: unset;
    padding: unset;
  }

  p:empty::before {
    content: '';
    display: inline-block;
  }
}
